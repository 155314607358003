import React, { useEffect, useState } from "react";
import AutoSuggest from "react-autosuggest";
import Switch from "react-bootstrap-switch";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { capitalizeFirst, checkPrevious } from "../../../constants/Utils";
import IAddress from "../../../interfaces/IAddress";
import IContact from "../../../interfaces/IContact";
import IEmail from "../../../interfaces/IEmail";
import IPhone from "../../../interfaces/IPhone";
import { request } from "../../../utils/axios-utils";
import Divider from "../../layout/Divider";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";
import InnerAddressForm from "./InnerAddressForm";
import InnerEmailForm from "./InnerEmailForm";
import InnerPhoneForm from "./InnerPhoneForm";

const CreateUpdate: React.FC = () => {
    const [contact, setContact] = useState<IContact | null>(null);
    const [addresses, setAddresses] = useState<Array<IAddress>>([]);
    const [phones, setPhones] = useState<Array<IPhone>>([]);
    const [emails, setEmails] = useState<Array<IEmail>>([]);

    const [addressIndex, setAddressIndex] = useState<number>(0);
    const [phoneIndex, setPhoneIndex] = useState<number>(0);
    const [emailIndex, setEmailIndex] = useState<number>(0);

    const history = useHistory();

    // One hook for each form field for the bank
    const [contactOrganism, setContactOrganism] = useState<string>("");
    const [contactCivility, setContactCivility] = useState<string>();
    const [contactFirstName, setContactFirstName] = useState<string>("");
    const [contactLastName, setContactLastName] = useState<string>("");
    const [contactProfession, setContactProfession] = useState<string>("");
    const [contactNotes, setContactNotes] = useState<string>("");
    const [familyChecked, setFamilyChecked] = useState<boolean>(false);
    const [urgenceChecked, setUrgenceChecked] = useState<boolean>(false);
    const [iban, setIban] = useState<string>("");
    const [bic, setBic] = useState<string>("");

    const [contactAutocompletionValue, setContactAutocompletionValue] =
        useState<string>("");
    const [contactGroups, setContactGroups] = useState<any>(null);
    const [
        suggestionsAutocompletionForContact,
        setSuggestionsAutocompletionForContact,
    ] = useState<any>([]);

    const { contactId }: { contactId?: string } = useParams();
    const { majeurId }: { majeurId?: string } = useParams();

    const { addToast } = useToasts();

    interface Language {
        name: string;
        year: number;
    }

    const Autosuggest = AutoSuggest as { new (): AutoSuggest<Language> };

    useEffect(() => {
        // Get all banks labels
        if (!contactGroups) {
            request({ addToast, url: `/api/contacts/contactGroups` })
                .then(({ data }) => {
                    setContactGroups(data);
                })
                .catch();
        }

        // If it is a modification, it loads the bank to modify.
        if (contactId && !contact) {
            request({ addToast, url: `/api/contacts/${contactId}` })
                .then(({ data }) => {
                    setContact(data);
                    setContactOrganism(data.organisme);
                    setContactCivility(data.civility);
                    setContactFirstName(data.first_name);
                    setContactLastName(data.last_name);
                    setContactProfession(data.profession);
                    setContactNotes(data.notes);
                    setIban(data.iban);
                    setBic(data.bic);

                    if (data.addresses && data.addresses.length > 0) {
                        data.addresses.forEach(function (a: any, index: any) {
                            a["id"] = `Address-${index}`;
                        });

                        setAddresses(data.addresses);
                        setAddressIndex(data.addresses.length);
                    }

                    if (data.phones && data.phones.length > 0) {
                        data.phones.forEach(function (p: any, index: any) {
                            p["id"] = `phone-${index}`;
                        });

                        setPhones(data.phones);
                        setPhoneIndex(data.phones.length);
                    }

                    if (data.emails && data.emails.length > 0) {
                        data.emails.forEach(function (e: any, index: any) {
                            e["id"] = `email-${index}`;
                        });

                        setEmails(data.emails);
                        setEmailIndex(data.emails.length);
                    }

                    // CHECK IF THIS PA IS URGENT OR FAMILLY
                    if (majeurId) {
                        data.familly.forEach((item: any) => {
                            if (item._id === majeurId) {
                                setFamilyChecked(item.familly);
                            }
                        });

                        data.urgent.forEach((item: any) => {
                            if (item._id === majeurId) {
                                setUrgenceChecked(item.urgent);
                            }
                        });
                    }
                })
                .catch();
        }
    }, [contact, contactId]);

    //Add a Phone, Email or Adress
    const addSomething = (type: string) => {
        switch (type) {
            case "Address":
                const addressList = [...addresses];
                if (checkPrevious(addressList, "addresses")) {
                    addresses.forEach((p) => (p.isDeletable = false));
                    addresses.push({
                        id: `Address-${addressIndex}`,
                        isDeletable: true,
                    });
                    setAddressIndex(addressIndex + 1);
                } else {
                    addToast("Ajoutez d'abord une addresses.", {
                        appearance: "warning",
                    });
                }
                break;

            case "Phone":
                const phonesList = [...phones];
                if (checkPrevious(phonesList, "phones")) {
                    phones.forEach((p) => (p.isDeletable = false));
                    phones.push({
                        id: `phone-${phoneIndex}`,
                        isDeletable: true,
                    });
                    setPhoneIndex(phoneIndex + 1);
                } else {
                    addToast("Ajoutez d'abord un numéro de téléphone.", {
                        appearance: "warning",
                    });
                }
                break;

            case "Email":
                const emailsList = [...emails];
                if (checkPrevious(emailsList, "emails")) {
                    emails.forEach((p) => (p.isDeletable = false));
                    emails.push({
                        id: `email-${emailIndex}`,
                        isDeletable: true,
                    });
                    setEmailIndex(emailIndex + 1);
                } else {
                    addToast("Ajoutez d'abord une adresse email.", {
                        appearance: "warning",
                    });
                }
                break;

            default:
                break;
        }
    };

    //Modify a Phone, Email or Adress
    const setSomethingValue = (type: string, object: any) => {
        let index = 0;
        switch (type) {
            case "Address":
                index = addresses.findIndex((a) => a.id === object.id);
                let newAddresses = addresses;
                newAddresses[index] = object;
                setAddresses(newAddresses);
                break;

            case "Phone":
                index = phones.findIndex((p) => p.id === object.id);
                let newPhones = phones;
                newPhones[index] = object;
                setPhones(newPhones);
                break;

            case "Email":
                index = emails.findIndex((e) => e.id === object.id);
                let newEmails = emails;
                newEmails[index] = object;
                setEmails(newEmails);
                break;

            default:
                break;
        }
    };

    //Delete a Phone, Email or Adress
    const removeSomething = (type: string, object: any) => {
        let index = 0;
        switch (type) {
            case "Address":
                index = addresses.findIndex((a) => a.id === object.id);
                let newAddresses = addresses;
                newAddresses.splice(index, 1);
                newAddresses.forEach(
                    (p, index) =>
                        (p.isDeletable = index === newAddresses.length - 1)
                );
                setAddresses(newAddresses);
                setAddressIndex(addressIndex - 1);
                break;

            case "Phone":
                index = phones.findIndex((p) => p.id === object.id);
                let newPhones = phones;
                newPhones.splice(index, 1);
                newPhones.forEach(
                    (a, index) =>
                        (a.isDeletable = index === newPhones.length - 1)
                );
                setPhones(newPhones);
                setPhoneIndex(phoneIndex - 1);
                break;

            case "Email":
                index = emails.findIndex((e) => e.id === object.id);
                let newEmails = emails;
                newEmails.splice(index, 1);
                newEmails.forEach(
                    (e, index) =>
                        (e.isDeletable = index === newEmails.length - 1)
                );
                setEmails(newEmails);
                setEmailIndex(emailIndex - 1);
                break;

            default:
                break;
        }
    };

    /**
     * Save a contact (it can be a saving as a modification).
     */
    const saveContact = () => {
        const url =
            contactId || contact
                ? `/api/contacts/${contact._id}/edit`
                : `/api/contacts/create/${contactId}`;

        if (
            contactOrganism === "" &&
            contactLastName === "" &&
            contactFirstName === ""
        ) {
            addToast(
                "Il faut au moins ajouter un organisme ou un contact personnel.",
                {
                    appearance: "warning",
                }
            );
            return;
        }
        request({
            addToast,
            url: url,
            method: "post",
            data: {
                organisme: capitalizeFirst(contactOrganism),
                civility: contactCivility,
                first_name: capitalizeFirst(contactFirstName),
                last_name: capitalizeFirst(contactLastName),
                profession: capitalizeFirst(contactProfession),
                notes: contactNotes,
                addresses: addresses,
                phones: phones,
                emails: emails,
                iban: iban,
                bic: bic,
                urgent: urgenceChecked,
                familly: familyChecked,
                majeur_id: majeurId,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(
                    `Le contact a bien été ${contactId ? "modifié" : "créé"}`,
                    { appearance: "success" }
                );
                contactId
                    ? !majeurId
                        ? history.push(`/contacts/${contactId}/show`)
                        : history.push(
                              `/contacts/${contactId}/show/${majeurId}`
                          )
                    : history.push(
                          majeurId
                              ? `/single_majeur/${majeurId}/contacts`
                              : "/contacts"
                      );
            })
            .catch();
    };

    /* Start Autosuggestion*/
    const renderSectionTitle = (section: any) => {
        return (
            <h5 className="section_autosuggest_contacts">
                {section.profession}
            </h5>
        );
    };

    const getSectionSuggestions = (section: any) => {
        return section.contacts;
    };

    const getSuggestionValueContactAutocompletion = (contact: any) => {
        // Here set all input values with selection attributes
        setContact(contact);
        setContactOrganism(contact.organisme);
        setContactCivility(contact.civility);
        setContactFirstName(contact.first_name);
        setContactLastName(contact.last_name);
        setContactProfession(contact.profession);
        setContactNotes(contact.notes);
        setIban(contact.iban);
        setBic(contact.bic);
        if (contact.addresses && contact.addresses.length > 0) {
            contact.addresses.forEach(function (a: any, index: any) {
                a["id"] = `Address-${index}`;
            });

            setAddresses(contact.addresses);
            setAddressIndex(contact.addresses.length);
        }

        if (contact.phones && contact.phones.length > 0) {
            contact.phones.forEach(function (p: any, index: any) {
                p["id"] = `phone-${index}`;
            });

            setPhones(contact.phones);
            setPhoneIndex(contact.phones.length);
        }

        if (contact.emails && contact.emails.length > 0) {
            contact.emails.forEach(function (e: any, index: any) {
                e["id"] = `email-${index}`;
            });

            setEmails(contact.emails);
            setEmailIndex(contact.emails.length);
        }

        if (contact.organisme) {
            return `${contact.organisme}`;
        }

        return `${contact.first_name} ${contact.last_name}`;
    };

    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestionsContactAutocompletion = (
        value: any,
        groupContactsByGroupName: any
    ) => {
        const escapedValue = value
            ? value.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
            : "";
        if (escapedValue === "") return [];
        const regex = new RegExp(escapedValue, "i");

        if (groupContactsByGroupName) {
            return Object.keys(groupContactsByGroupName)
                .map((groupName: any) => ({
                    profession:
                        groupName /*groupContactsByGroupName[groupName]*/,
                    contacts: groupContactsByGroupName[groupName] /*.contacts*/
                        .filter((contact: IContact) =>
                            contact.organisme
                                ? regex.test(contact.organisme)
                                : regex.test(
                                      contact.first_name +
                                          " " +
                                          contact.last_name
                                  )
                        ),
                }))
                .filter(
                    (filteringGroup: any) => filteringGroup.contacts.length > 0
                );
        }

        return [];
    };

    // Use your imagination to render suggestions.
    const renderSuggestionContactsGroupAutocompletion = (suggestion: any) => {
        if (suggestion.organisme) {
            return <span>{suggestion.organisme}</span>;
        }
        return (
            <span>
                {suggestion.first_name} {suggestion.last_name}
            </span>
        );
    };

    // Update input for bank in the state
    const onChangeContactAutocompletionValue = (newValue: any) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        setContactAutocompletionValue(newValue);
    };

    const onBlurBank = () => {};
    /* End Autosuggestion*/

    return (
        <>
            <div className="Contact create_contact">
                {!contactId && !contact && (
                    <SubBar
                        title={"Ajouter un contact"}
                        back={
                            majeurId
                                ? "/single_majeur/" + majeurId
                                : "/contacts"
                        }
                    />
                )}

                {contactId && contact && (
                    <SubBar
                        title={`Modifier le contact`}
                        back={
                            "/contacts/" +
                            contactId +
                            "/show" +
                            (majeurId ? "/" + majeurId : "")
                        }
                    />
                )}

                <div className="page_content">
                    {contactId && !contact && <Loader />}

                    {majeurId && (
                        <div
                            className="reset_form_container"
                            style={{ marginBottom: 20 }}
                        >
                            <div className="my-input-container column">
                                <Autosuggest
                                    multiSection
                                    renderSectionTitle={(section: any) =>
                                        renderSectionTitle(section)
                                    }
                                    getSectionSuggestions={(section: any) =>
                                        getSectionSuggestions(section)
                                    }
                                    suggestions={
                                        suggestionsAutocompletionForContact
                                    }
                                    onSuggestionsFetchRequested={(
                                        section: any
                                    ) =>
                                        setSuggestionsAutocompletionForContact(
                                            getSuggestionsContactAutocompletion(
                                                section.value,
                                                contactGroups
                                            )
                                        )
                                    }
                                    onSuggestionsClearRequested={() =>
                                        setSuggestionsAutocompletionForContact(
                                            []
                                        )
                                    }
                                    getSuggestionValue={(contact: any) =>
                                        getSuggestionValueContactAutocompletion(
                                            contact
                                        )
                                    }
                                    renderSuggestion={(contact: any) =>
                                        renderSuggestionContactsGroupAutocompletion(
                                            contact
                                        )
                                    }
                                    inputProps={{
                                        placeholder: "Rechercher un contact...",
                                        value: contactAutocompletionValue,
                                        onChange: (
                                            event: any,
                                            section: any
                                        ) => {
                                            onChangeContactAutocompletionValue(
                                                section.newValue
                                            );
                                        },
                                        onBlur: () => onBlurBank(),
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {(!contactId || contact) && (
                        <form className="column">
                            <div className="row layout-xs-column">
                                <div className="my-input-container column-item-flex-1">
                                    <div className="flex005 hide-xs" />

                                    <div className="row layout-xs-column">
                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Organisme"
                                            value={contactOrganism || ""}
                                            onChange={(e) =>
                                                setContactOrganism(
                                                    e.target.value
                                                )
                                            }
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    <div className="row layout-xs-column">
                                        <Select
                                            className="flex-1"
                                            id={
                                                (contact &&
                                                    `${contact.id}-civility`) ||
                                                ""
                                            }
                                            label="Civilité"
                                            value={
                                                (contactCivility &&
                                                    contactCivility) ||
                                                ""
                                            }
                                            needDefaultValue={true}
                                            options={[
                                                {
                                                    value: "Madame",
                                                    label: "Madame",
                                                },
                                                {
                                                    value: "Monsieur",
                                                    label: "Monsieur",
                                                },
                                            ]}
                                            onChange={(e) =>
                                                setContactCivility(
                                                    e.target.value
                                                )
                                            }
                                        />

                                        <div className="flex005 hide-xs" />

                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Prénom"
                                            value={contactFirstName || ""}
                                            onChange={(e) =>
                                                setContactFirstName(
                                                    e.target.value
                                                )
                                            }
                                            maxLength={50}
                                        />

                                        <div className="flex005 hide-xs" />

                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Nom"
                                            value={contactLastName || ""}
                                            onChange={(e) =>
                                                setContactLastName(
                                                    e.target.value
                                                )
                                            }
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    <InputComponent
                                        id="label"
                                        type="text"
                                        className="column-item-flex-1"
                                        label="Profession"
                                        value={contactProfession || ""}
                                        onChange={(e) =>
                                            setContactProfession(e.target.value)
                                        }
                                        maxLength={50}
                                    />

                                    <div className="flex005 hide-xs" />

                                    <InputComponent
                                        id="label"
                                        type="text"
                                        className="column-item-flex-1"
                                        label="IBAN"
                                        value={iban || ""}
                                        onChange={(e) =>
                                            setIban(e.target.value)
                                        }
                                        maxLength={50}
                                    />

                                    <div className="flex005 hide-xs" />
                                    <InputComponent
                                        id="label"
                                        type="text"
                                        className="column-item-flex-1"
                                        label="BIC"
                                        value={bic || ""}
                                        onChange={(e) => setBic(e.target.value)}
                                        maxLength={50}
                                    />

                                    <div className="flex005 hide-xs" />
                                    {/* Block for addresses linked */}
                                    <div className="block">
                                        <div className="my-sub-title-f">
                                            <SubBar title="Adresses">
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        addSomething("Address")
                                                    }
                                                >
                                                    <Icon name="add" />
                                                </Link>
                                            </SubBar>
                                        </div>

                                        <div className="layout-center-start column">
                                            {addresses &&
                                                addresses.map(
                                                    (address, index) => (
                                                        <div
                                                            key={index}
                                                            className="column full-width"
                                                        >
                                                            <InnerAddressForm
                                                                key={address.id}
                                                                id={address.id}
                                                                removeAddress={(
                                                                    address: IAddress
                                                                ) =>
                                                                    removeSomething(
                                                                        "Address",
                                                                        address
                                                                    )
                                                                }
                                                                onChange={(
                                                                    address: IAddress
                                                                ) =>
                                                                    setSomethingValue(
                                                                        "Address",
                                                                        address
                                                                    )
                                                                }
                                                                isDeletable={
                                                                    addresses.length -
                                                                        1 ===
                                                                    index
                                                                }
                                                                {...address}
                                                            />
                                                            {addresses.length !=
                                                                index + 1 && (
                                                                <Divider />
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    {/* Block for phones linked */}
                                    <div className="block">
                                        <div className="my-sub-title-f">
                                            <SubBar title="Téléphones">
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        addSomething("Phone")
                                                    }
                                                >
                                                    <Icon name="add" />
                                                </Link>
                                            </SubBar>
                                        </div>

                                        <div className="layout-center-start column">
                                            {phones.map((phone, index) => (
                                                <div
                                                    key={index}
                                                    className="column full-width"
                                                >
                                                    <InnerPhoneForm
                                                        key={phone.id}
                                                        id={phone.id}
                                                        removePhone={(
                                                            phone: IPhone
                                                        ) =>
                                                            removeSomething(
                                                                "Phone",
                                                                phone
                                                            )
                                                        }
                                                        onChange={(
                                                            phone: IPhone
                                                        ) =>
                                                            setSomethingValue(
                                                                "Phone",
                                                                phone
                                                            )
                                                        }
                                                        isDeletable={
                                                            phones.length -
                                                                1 ===
                                                            index
                                                        }
                                                        {...phone}
                                                    />
                                                    {phones.length !=
                                                        index + 1 && (
                                                        <Divider />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    {/* Block for mails linked */}
                                    <div className="block">
                                        <div className="my-sub-title-f">
                                            <SubBar title="Emails">
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        addSomething("Email")
                                                    }
                                                >
                                                    <Icon name="add" />
                                                </Link>
                                            </SubBar>
                                        </div>

                                        <div className="layout-center-start column">
                                            {emails.map((email, index) => (
                                                <div
                                                    key={index}
                                                    className="column full-width"
                                                >
                                                    <InnerEmailForm
                                                        key={email.id}
                                                        id={email.id}
                                                        removeEmail={(
                                                            email: IEmail
                                                        ) =>
                                                            removeSomething(
                                                                "Email",
                                                                email
                                                            )
                                                        }
                                                        onChange={(
                                                            email: IEmail
                                                        ) =>
                                                            setSomethingValue(
                                                                "Email",
                                                                email
                                                            )
                                                        }
                                                        isDeletable={
                                                            emails.length -
                                                                1 ===
                                                            index
                                                        }
                                                        {...email}
                                                    />
                                                    {emails.length !=
                                                        index + 1 && (
                                                        <Divider />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    <label>Notes :</label>
                                    <textarea
                                        className="my-textarea"
                                        value={contactNotes || ""}
                                        onChange={(e) =>
                                            setContactNotes(e.target.value)
                                        }
                                        placeholder="Notes..."
                                    ></textarea>
                                    {majeurId && (
                                        <div className="column full-width">
                                            <Switch
                                                onChange={() =>
                                                    setFamilyChecked(
                                                        !familyChecked
                                                    )
                                                }
                                                labelText="famille"
                                                bsSize="small"
                                                wrapperClass="wrapper"
                                                onText="OUI"
                                                onColor="success"
                                                offColor="danger"
                                                offText="NON"
                                                value={familyChecked}
                                            />
                                            <Switch
                                                onChange={() =>
                                                    setUrgenceChecked(
                                                        !urgenceChecked
                                                    )
                                                }
                                                labelText="contact d'urgence"
                                                bsSize="small"
                                                labelWidth={120}
                                                wrapperClass="wrapper"
                                                onText="OUI"
                                                onColor="success"
                                                offColor="danger"
                                                offText="NON"
                                                value={urgenceChecked}
                                            />
                                        </div>
                                    )}

                                    <FormButton
                                        onClick={saveContact}
                                        style={{ marginTop: 20 }}
                                        text={
                                            !contactId ? "Ajouter" : "Modifier"
                                        }
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};

export default CreateUpdate;
