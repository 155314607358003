import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { formatTelephone } from "../../../constants/Utils";
import IAddress from "../../../interfaces/IAddress";
import IContact from "../../../interfaces/IContact";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import Modal from "../../layout/modals/Modal";

const Contact: React.FC = () => {
    const [contact, setContact] = useState<IContact | null>(null);
    const [confirmDeletion, setConfirmDeletion] = useState(false);

    const history = useHistory();
    const { contactId, majeurId }: { contactId: string; majeurId: string } =
        useParams();
    const { addToast } = useToasts();

    useEffect(() => {
        if (!contact) {
            request({ addToast, url: `/api/contacts/${contactId}` })
                .then(({ data }) => setContact(data))
                .catch(() =>
                    history.push(
                        majeurId ? `/single_majeur/${majeurId}` : "/contacts"
                    )
                );
        }
    }, [contact]);

    const deleteContact = () => {
        setConfirmDeletion(false);
        request({
            addToast,
            url: `/api/contacts/${contactId}/delete/${majeurId}`,
            method: "post",
            data: {},
        })
            .then(() => {
                addToast("Le contact a bien été supprimé", {
                    appearance: "success",
                });
                history.push(
                    majeurId
                        ? `/single_majeur/${majeurId}/contacts`
                        : "/contacts"
                );
            })
            .catch();
    };

    return (
        <>
            <SubBar
                title={contact ? "Fiche Contact" : "Chargement..."}
                back={
                    majeurId
                        ? `/single_majeur/${majeurId}/contacts`
                        : "/contacts"
                }
            >
                <Link
                    to={`/contacts/${contactId}/modifier${
                        majeurId ? `/${majeurId}` : ""
                    }`}
                    className="text-gray-600 hover:text-blue-600 transition"
                >
                    <Icon name="edit" />
                </Link>
                <button
                    onClick={() => setConfirmDeletion(true)}
                    className="text-red-600 hover:text-red-800"
                >
                    <Icon name="delete_forever" />
                </button>
            </SubBar>

            <Modal
                message="⚠️ La suppression du contact est définitive. Êtes-vous sûr(e) ?"
                show={confirmDeletion}
                onValidate={deleteContact}
            />

            <div className="max-w-4xl mx-auto p-6 space-y-8">
                {!contact ? (
                    <Loader />
                ) : (
                    <>
                        {/* Header */}
                        <div className="text-center">
                            <h1 className="text-3xl font-bold text-gray-800">
                                {contact.first_name
                                    ? `${
                                          contact.first_name
                                      } ${contact.last_name.toUpperCase()}`
                                    : contact.last_name.toUpperCase()}
                            </h1>
                            {contact.organisme && (
                                <p className="text-sm text-gray-500 mt-1 uppercase">
                                    {contact.organisme}
                                </p>
                            )}
                            {contact.profession && (
                                <p className="text-sm text-gray-600 mt-1">
                                    {contact.profession}
                                </p>
                            )}
                        </div>

                        {/* Informations */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Adresses */}
                            {contact.addresses?.length > 0 && (
                                <Card title="Adresses">
                                    <div className="space-y-4">
                                        {contact.addresses.map(
                                            (
                                                address: IAddress,
                                                index: number
                                            ) => (
                                                <div key={index}>
                                                    <h6 className="font-semibold">
                                                        {address.label}
                                                    </h6>
                                                    <p>{address.address}</p>
                                                    <p>
                                                        {address.zip_code}{" "}
                                                        {address.city}
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </Card>
                            )}

                            {/* Téléphones */}
                            {contact.phones?.length > 0 && (
                                <Card title="Téléphones">
                                    <div className="space-y-2">
                                        {contact.phones.map((tel, index) => (
                                            <div key={index}>
                                                <h6 className="font-semibold">
                                                    {tel.label}
                                                </h6>
                                                <p>
                                                    {formatTelephone(tel.phone)}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            )}

                            {/* Emails */}
                            {contact.emails?.length > 0 && (
                                <Card title="Emails">
                                    <div className="space-y-2">
                                        {contact.emails.map((mail, index) => (
                                            <div key={index}>
                                                <h6 className="font-semibold">
                                                    {mail.label}
                                                </h6>
                                                <a
                                                    href={`mailto:${mail.email}`}
                                                    className="text-blue-600 hover:underline"
                                                >
                                                    {mail.email}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            )}
                            {/* IBAN */}
                            {contact.iban && contact.bic && (
                                <Card title="Facturation">
                                    {contact?.iban && (
                                        <p>IBAN :{contact.iban}</p>
                                    )}
                                    {contact?.bic && <p>BIC : {contact.bic}</p>}
                                </Card>
                            )}

                            {/* Notes */}
                            <Card title="Notes">
                                <p>{contact.notes || "Non renseignées"}</p>
                            </Card>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Contact;

// Composant Card utilisé pour structurer chaque section
const Card: React.FC<{ title: string; children: React.ReactNode }> = ({
    title,
    children,
}) => (
    <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200 hover:shadow-xl transition-shadow duration-300">
        <h2 className="text-lg font-semibold mb-4 text-gray-800">{title}</h2>
        <div className="text-gray-700">{children}</div>
    </div>
);
